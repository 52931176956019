import React, { useContext, useMemo, useState, useEffect } from "react";
import {
  Input,
  Modal,
  Form,
  Select,
  Button,
  notification,
  Row,
  Col,
} from "antd";
import { AuthContext } from "../AuthContext";
import countryList from "react-select-country-list";
import { useNavigate } from "react-router-dom";
import { webAPIs } from "../config/webAPIs";
import "react-phone-number-input/style.css";
import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input";
import { formatNumber } from "../utils";

const { Option } = Select;

const AccountSetting = () => {
  const { user, refreshUserData, systemRoles, adminPrompt } =
    useContext(AuthContext);
  const [visible, setVisible] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [informationVisible, setInformationVisible] = useState(false);
  const [isInformation, setIsInformation] = useState(false);
  const [form] = Form.useForm();
  const [passwordForm] = Form.useForm();
  const options = useMemo(() => countryList().getData(), []);
  const navigate = useNavigate();
  const extractLanguage = () => {
    const pattern = /Please write in (\w+)/;
    const match = adminPrompt.match(pattern);
    if (match && match[1]) {
      return match[1];
    }
    return "N/A";
  };

  useEffect(() => { refreshUserData() }, []);
  
  const handleClick = () => {
    setVisible(true);
    setIsOpen(!isOpen);
  };

  const handleInformation = () => {
    form.setFieldsValue({
      firstName: user?.firstName,
      lastName: user?.lastName,
      phoneNumber: user?.phoneNumber,
      street: user?.street,
      postalCode: user?.postalCode,
      city: user?.city,
      country: user?.country,
      status: user?.status,
      companyName: user?.companyName,
    });
    setInformationVisible(true);
    setIsInformation(!isInformation);
  };

  const validatePhoneNumber = (_, value) => {
    if (!value || isPossiblePhoneNumber(value)) {
      return Promise.resolve();
    } else {
      return Promise.reject(new Error("Please enter a valid phone number!"));
    }
  };

  const noWhiteSpace = (_, value) => {
    if (!value || value.trim().length === 0) {
      return Promise.reject(new Error("This field cannot be blank!"));
    }
    return Promise.resolve();
  };

  const onFinish = async () => {
    passwordForm.submit();
    try {
      await fetch(webAPIs.changePassword, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          password: passwordForm.getFieldValue("newPassword"),
          user: user.id,
        }),
      });
      setVisible(false);
      passwordForm.setFieldsValue({
        newPassword: "",
        confirm: "",
      });
      notification.success({
        description: "Change Password Successful",
        duration: 1,
      });
    } catch (error) {
      console.log(error);
      notification.error({
        description: "Internal Server Error",
      });
    }
  };

  const handleManagePayment = async () => {
    try {
      const response = await fetch(webAPIs.createPortalSession, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user: user.id,
        }),
      });
      if (response.ok) {
        const data = await response.json();
        // Redirect to the Stripe Customer Portal
        window.location.href = data.url;
      } else {
        throw new Error("Failed to create portal session");
      }
    } catch (error) {
      console.error("Error:", error);
      notification.error({
        description: `${error}`,
      });
    }
  };  

  const onInformationFinish = async () => {
    form.submit();
    try {
      const formValues = form.getFieldsValue();
      const response = await fetch(webAPIs.changeInformation, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...formValues,
          user: user.id,
        }),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setVisible(false);
      form.resetFields();
      await refreshUserData();
      notification.success({
        description: data.message,
        duration: 1,
      });
    } catch (error) {
      notification.error({
        description: error.message || "Internal Server Error",
      });
    }
  };

  const DisplayRole = ({ title, des }) => {
    return (
      <div className="flex w-full gap-3">
        <span className="sm:w-40 w-32">{title}</span>
        <span className="text-sm font-normal">{des}</span>
      </div>
    );
  };

  return (
    <div className="w-full flex flex-col p-5 gap-5">
      <span className="w-full bg-sidebarColor text-white p-2 rounded-sm">
        Team Account Settings
      </span>
      <div className="flex sm:flex-row flex-col w-full gap-2">
        <div className="border sm:w-3/5 w-full flex flex-col gap-1 p-3">
          <div className="flex sm:flex-row flex-col items-center h-full gap-2">
            <div className="w-full flex flex-col gap-2">
              <DisplayRole
                title="Your Role"
                des={systemRoles[user?.systemRole - 1]?.title}
              />
              <DisplayRole title="Output Language" des={extractLanguage()} />
              {/*<div className="flex w-full gap-3">
                <span className="w-40">Uploaded Docs</span>
                <span className="text-sm font-normal overflow-y-scroll overflow-x-hidden max-h-20">
                  {files &&
                    files.map((item, index) => (
                      <div className="" key={index}>
                        {item?.originalName}
                      </div>
                    ))}
                </span>
              </div>
              <DisplayRole title="Product Category" des="System.laugnage" />*/}
            </div>
            <div className="flex flex-row sm:flex-col justify-between h-full gap-2">
              <Button
                className="bg-[#d8ae5f]"
                onClick={() => {
                  navigate("/dashboard/onboarding");
                }}
              >
                Change Team Role
              </Button>
              <Button
                className="bg-[#d8ae5f]"
                onClick={() => {
                  navigate("/dashboard/admin/advance");
                }}
              >
                {" "}
                Advanced Settings{" "}
              </Button>
            </div>
          </div>
        </div>
        <div className="border flex-1 gap-1 p-3">
          <div className="flex sm:flex-row flex-col items-center h-full gap-2">
            <div className="w-full flex flex-col gap-2 h-full">
              <DisplayRole title="Subscription" des={user?.subscriptionName} />
              <DisplayRole title="Token used" des={formatNumber(user?.totalTokensAllTime)} />
              <DisplayRole
                title="Token available"
                des={formatNumber(user?.tokenLeft)}
              />
              {user?.paymentMethod && (
                <DisplayRole title="Payment method" des={`*${user.paymentMethod}`} />
              )}
            </div>
            <div className="flex sm:flex-col flex-row justify-between w-full gap-3 h-full">
              <Button className="bg-[#d8ae5f]" onClick={() => {
                navigate("/dashboard/admin/subscriptions");
              }}>
                Change Subscription
              </Button>
              {user?.paymentMethod && (
                <Button className="bg-[#d8ae5f]" onClick={handleManagePayment}>
                  Update Payment method
                </Button>
              )}
              {/* <Button className="bg-[#d8ae5f]" disabled>
                Top up
              </Button> */}
            </div>
          </div>
        </div>
      </div>
      <span className="w-full bg-sidebarColor text-white p-2 rounded-sm">
        User Account Information
      </span>
      <div className="sm:w-3/5 w-full border flex flex-col gap-2 p-3">
        <div className="flex items-center h-full sm:flex-row flex-col gap-2">
          <div className="w-full flex flex-col gap-2">
            <DisplayRole title="Account" des={user?.email} />
            <div className="flex w-full gap-3">
              <span className="sm:w-40 w-32">Password</span>
              <input
                type="password"
                className="text-sm font-normal"
                value={user?.password}
              />
            </div>
          </div>
          <div className="flex flex-col gap-3 h-full justify-start sm:max-w-min w-full">
            <Button className="bg-[#d8ae5f]" onClick={handleClick}>
              Change password
            </Button>
          </div>
        </div>
      </div>

      <div className="sm:w-3/5 w-full border flex flex-col gap-2 p-3">
        <div className="flex sm:flex-row flex-col items-center h-full gap-3">
          <div className="w-full flex flex-col gap-2">
            <DisplayRole title="First Name" des={user?.firstName} />
            <DisplayRole title="Last Name" des={user?.lastName} />
            <DisplayRole title="Phone" des={user?.phoneNumber} />
            <DisplayRole title="Company Name" des={user?.companyName} />
            <DisplayRole title="Street" des={user?.street} />
            <DisplayRole title="City" des={user?.city} />
            <DisplayRole title="Zip" des={user?.postalCode} />
            {user && (
              <div className="flex w-full gap-3">
                <span className="sm:w-40 w-32">Country</span>
                <Select
                  showSearch
                  optionFilterProp="children"
                  defaultValue={user.country}
                  disabled
                >
                  {options.map((option) => (
                    <Option key={option.value} value={option.value}>
                      {option.label}
                    </Option>
                  ))}
                </Select>
              </div>
            )}
          </div>
          <div className="flex flex-col gap-3 h-full sm:max-w-min w-full">
            <Button className="bg-[#d8ae5f]" onClick={handleInformation}>
              Edit Information
            </Button>
          </div>
        </div>
      </div>

      <Modal
        open={visible}
        onOk={() => {
          passwordForm
            .validateFields() // Trigger form validation
            .then(() => {
              onFinish();
              setVisible(false); // You can then close the Modal if needed
            })
            .catch((info) => {
              console.log("Validate Failed:", info); // Handle the form validation failure if necessary
            });
        }}
        onCancel={() => setVisible(false)}
        okButtonProps={{ className: "bg-[#007bff]" }}
        width={600}
      >
        <div className="mt-2 mb-4 text-xl">Change Password</div>
        <Form
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          form={passwordForm}
        >
          <Form.Item
            name="newPassword"
            label="New Password"
            rules={[
              {
                required: true,
                message: "Please enter your new password",
              },
              {
                min: 8,
                message: "Password must be at least 8 characters long",
              },
              {
                pattern: /^(?=.*[a-z]).*$/,
                message: "Password must contain at least one lowercase letter",
              },
              {
                pattern: /^(?=.*[A-Z]).*$/,
                message: "Password must contain at least one uppercase letter",
              },
              {
                pattern: /^(?=.*\d).*$/,
                message: "Password must contain at least one number",
              },
              {
                pattern: /^(?=.*[\W_]).*$/,
                message: "Password must contain at least one special character",
              },
            ]}
            hasFeedback
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            name="confirm"
            label="Confirm Password"
            dependencies={["newPassword"]}
            rules={[
              {
                required: true,
                message: "Please confirm your new password",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("newPassword") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("The two passwords that you entered do not match")
                  );
                },
              }),
            ]}
            hasFeedback
          >
            <Input.Password />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        open={informationVisible}
        onOk={() => {
          form
            .validateFields() // Trigger form validation
            .then(() => {
              onInformationFinish(form.getFieldsValue()); // If validation is successful, call the onFinish handler
              setInformationVisible(false); // You can then close the Modal if needed
            })
            .catch((info) => {
              console.log("Validate Failed:", info); // Handle the form validation failure if necessary
            });
        }}
        onCancel={() => setInformationVisible(false)}
        okButtonProps={{ className: "bg-[#007bff]" }}
      >
        <div className="mt-2 mb-4 text-xl">Edit Information</div>
        <Form form={form} layout="vertical" autoComplete="off">
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="First Name"
                name="firstName"
                rules={[
                  { required: true, message: "Please input your first name!" },
                  { validator: noWhiteSpace },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Last Name"
                name="lastName"
                rules={[
                  { required: true, message: "Please input your last name!" },
                  { validator: noWhiteSpace },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Company Name"
                name="companyName"
                rules={[
                  {
                    required: true,
                    message: "Please input your Company Name!",
                  },
                  { validator: noWhiteSpace },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Street"
                name="street"
                rules={[
                  { required: true, message: "Please input your street address!" },
                  { validator: noWhiteSpace },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="City"
                name="city"
                rules={[
                  { required: true, message: "Please input your city!" },
                  { validator: noWhiteSpace },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Postal Code"
                name="postalCode"
                rules={[
                  { required: true, message: "Please input your postal code!" },
                  { validator: noWhiteSpace },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Country"
                name="country"
                rules={[
                  { required: true, message: "Please select your country!" },
                  { validator: noWhiteSpace },
                ]}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  defaultValue={user?.country}
                >
                  {options.map((option) => (
                    <Option key={option.value} value={option.value}>
                      {option.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Phone Number"
                name="phoneNumber"
                rules={[
                  {
                    required: true,
                    message: "Please input your phone number!",
                  },
                  { validator: validatePhoneNumber },
                ]}
              >
                <PhoneInput
                  international
                  defaultCountry="CH"
                  placeholder="Enter phone number"
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};

export default AccountSetting;
