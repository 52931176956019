import { notification } from "antd";
import numeral from "numeral";

export const formatNumber = (num) => {
  if (num >= 1000000) {
    return `${Math.floor(num / 1000)}K`;
  }
  return numeral(num).format('0.[0]a').toUpperCase();
};

export const subscriptionAlert = () => {
  notification.error({
    description: 'You do not have permission. Please upgrade your subscription.',
    duration: 3
  })
}